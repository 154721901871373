body{
    background: url(https://i.pinimg.com/originals/2a/7c/64/2a7c646bc9d4e19e485740e578710ae7.jpg);
	background-size: cover;
    font-size: 16px;
    font-family: 'Segoe UI Emoji', 'Segoe UI Symbol', 'Segoe UI', 'Apple Color Emoji', 'Twemoji Mozilla', 'Noto Color Emoji', 'Android Emoji', Times, Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, serif, LastResort;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    color: rgb(48, 43, 43);
}

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
}

img {
    max-width: 100%;
    height: auto;
}

input, button {
    border: none;
    background: none;
    outline: none;
    color: rgb(48, 43, 43);
    font-size: 16px;

}

a {
    color: rgb(48, 43, 43);
}

ul,li {
    padding: 0;
    margin: 0;
    list-style: none;
}


.container {
    width: 1240px;
    margin: 0 auto;
    min-height: 100vh;
}