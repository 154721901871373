.wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
    padding: 15px 0;
  }
  
  .header {
    width: 100%;
    color: #fff;
    background: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
  
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  
  .left {
    background: rgb(253, 73, 73);
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
  }
  
.form {
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    background: rgb(51, 51, 51);
    justify-content: space-between;
    height: 80px;
    padding: 0 20px;
    
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  
  .input {
    flex-grow: 1;
    height: 100%;
  }
  
  .input input {
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }
  
  .button {
    text-align: center;
  }
  
  .button input {
    cursor: pointer;
    color: #fff;
  }
  
  .messages {
    flex-grow: 1;
    width: 100%;
    color: #fff;
    font-size: 18px;
    background: rgba(10, 14, 14, 0.829);
    padding: 32px;
    overflow: auto;
    height: 400px;
  }

  .messages::-webkit-scrollbar {
    width: 8px; /* Ширина видимости прокрутки */
  }
  
  .messages::-webkit-scrollbar-thumb {
    background-color: #aaa; /* Цвет ползунка */
    border-radius: 4px; /* Закругление углов ползунка */
  }
  
  .messages::-webkit-scrollbar-track {
    background-color: transparent; /* Прозрачный фон */
  }
  
  .messages::-webkit-scrollbar-thumb:hover {
    background-color: #888; /* Цвет ползунка при наведении */
  }
  
  .messages::-webkit-scrollbar-thumb:active {
    background-color: #555; /* Цвет ползунка при активном состоянии (нажатие) */
  }


  /* */


  
  .emoji {
    width: 40px;
    cursor: pointer;
    position: relative;
  }
  
  .emoji img {
    vertical-align: middle;
  }
  
  .emojies {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
  }